(function($){
  'use strict';

  try {
    var statements = Drupal.settings.outcomes.statements;

    var $textOverlay = $('.text-overlay');
    var index = 0;
    var indexLength = statements.length;

    $textOverlay.html(statements[index].title);

    setInterval(function(){

      $textOverlay.fadeOut('slow', function() {
        $textOverlay.html(statements[index].title);
        $textOverlay.fadeIn('slow');
      });

      index++;
      if(index === indexLength){
        index = 0;
      }

    }, 5000);

  } catch(e){
  }

})(jQuery);
