(function($){
  'use strict';

  var $addressFieldsWrapper = $('.address-fields-wrapper');
  var $body = $('body');
  var $docEl = $('html, body');
  var $document = $(document);
  var $html = $('html');
  var $orderPrintCheckbox = $('#order-print-checkbox');
  var $referralQuestion = $('#referral-question');
  var $otherReferral = $('.other-referral');
  var $otherReferralField = $('.other-referral-field');
  var $organizationType = $('.organization-type-selection');
  var $otherOrganizationType = $('.other-organization-type');
  var $otherOrganizationTypeField = $('.other-organization-type-field');
  var $wrap = $('.off-canvas-content');

  var orderBookHash = '#order-book';
  var $bodyWidth = $('body').width();
  var scrollTop;


  $.lockBody = function() {
    if (window.pageYOffset) {
      scrollTop = window.pageYOffset;
      $wrap.css({top: - (scrollTop)});
    }
    $docEl.css({height: "100%",overflow: "hidden"});
  };

  $.unlockBody = function() {
    $docEl.css({height: "",overflow: ""});
    $wrap.css({top: ''});
    // window.scrollTo(0, scrollTop); // Original implementation to save scroll position for mobile
    window.scrollTo(0, 0); // Only used form, we want to scroll to top for callout to be shown
    window.setTimeout(function () {
      scrollTop = null;
    }, 0);
  };

  var checkUrlForBookForm = function(){
    if(window.location.hash === '#order-book'){
      $('.order-book a').click();
    }
  };


  // Offcanvas event handlers
  $body
    .on('opened.zf.offcanvas', function(){
      $.lockBody();

      if(window.location.hash !== '#order-book'){
        window.location.href = window.location.href + orderBookHash;
      }

      if(!$body.hasClass('book-form-opened') && $bodyWidth < 1024) {
        $body.addClass('book-form-opened');
        $('#responsive-menu').hide();
      } else {
        $body.addClass('book-form-opened');
      }

      $('.header .header-inner').foundation('_calc', true);
    })
    .on('closed.zf.offcanvas', function(){
      $.unlockBody();

      if(window.location.hash === '#order-book'){
        history.pushState("", document.title, window.location.href.replace(/\#(.+)/, '').replace(/http(s?)\:\/\/([^\/]+)/, '') );
      }

      if($body.hasClass('book-form-opened')){
        $body.removeClass('book-form-opened');
      }

      $('.header .header-inner').foundation('_calc', true);
    });

  // reCAPTCHA integration, need to make sure we grab CAPTCHA value for Abide to catch the associated
  // textfield we are filling in. This textfield can't be input="hidden" otherwise Abide ignores it.
  $('#submit-book-order-btn').on("click", function(ev) { $('#recaptcha-response-field').val(grecaptcha.getResponse()); return true; });

  // NOTE: Won't work if we pass form id along as jQuery selector
  $('#order-book-popup').on("formvalid.zf.abide", function(ev, form) {
      // Variables
      var serialized_form = '';

      // Serialize data & send to DB
      serialized_form = $('#order-book-form').serialize();

      $.getJSON( "/process-order", serialized_form, function( data ) {
        // JS / File Download Processing
        if (data.success === true) {
          // Only send PDF if they wanted to download
          /* if (data.download === true) {
            $("<form>")
              .attr("action", "/download/book")
              .attr("method", "post")
              .append(
                  $("<input>")
                      .attr("type", "hidden")
                      .attr("name", "captcha")
                      .attr("value", $('#recaptcha-response-field').val())
              )
              .appendTo("body")
              .submit()
              .remove();

              $('.pdf-downloading-message').show();
          } */

          $('#order-book-popup').foundation('close');
          $('#order-book-form').trigger('reset');
          // $('#order-book-popup .alert.callout').hide();

          if (data.redirect === true) {
            window.location = '/download-book';
          } else {
            $('.callout').show();
          }
        }
      });

    });

  // Ensure we don't let the submit handler run -- we ajax via Abide event listener
  $('#order-book-form').on("submit", function(e) {
      e.preventDefault();
  });

  // Abide validation
  Foundation.Abide.defaults.validators.checkbox_limit = function($el, required, parent) {
    var group = parent.closest('.checkbox-group');
    var min = group.attr('data-validator-min');
    var checked = group.find(':checked').length;
    if (checked >= min) {
      // clear label highlight
      group.find('label').each(function() {
        $(this).removeClass('is-invalid-label');
      });
      // clear checkbox error
      group.find(':checkbox').each(function() {
        $(this).removeClass('is-invalid-input').removeAttr('data-invalid');
      });
      group.find('.form-error').hide();
      return true;
    } else {
      // label highlight
      group.find('label').each(function() {
        $(this).addClass('is-invalid-label');
      });
      // checkbox error
      group.find(':checkbox').each(function() {
        $(this).addClass('is-invalid-input').attr('data-invalid');
      });
      group.find('.form-error').show();
      return false;
    }
  };

  Foundation.Abide.defaults.validators.less_than = function($el, required, parent) {
    if (!required) return true;
    var limit = $el.attr('data-less-than'),
        user = $el.val();

    return ((parseInt(user) < parseInt(limit)) && (parseInt(user) > 0));
  };


  var _handleOtherSelection = function($label, $field) {
    var $this = $(this);
    var $isOtherSelected = $this.is(function(){
      return $(this).val() === 'Other';
      });

    if($isOtherSelected){
      $label.show();
      $field.attr('required', '');
    } else {
      $label.hide();
      $field.removeAttr('required');
    }
  };

  var organizationCallback = function() {
    _handleOtherSelection.call($organizationType, $otherOrganizationType, $otherOrganizationTypeField);
  };

  var referralQuestionCallback = function() {
    _handleOtherSelection.call($referralQuestion, $otherReferral, $otherReferralField);
  };


  // Order Print Copy Address
  $orderPrintCheckbox.on('change', function(){
    var $addressInputFields = $addressFieldsWrapper.find('input');
    var $countrySelector = $('.country-selector');

    if($(this).is(':checked')){
      $addressFieldsWrapper.show();
      $countrySelector.attr('required', '');

      $addressInputFields.each(function(){
        $(this).attr('required', '');
      });
    } else if(!$(this).is(':checked')) {
      $addressFieldsWrapper.hide();
      $countrySelector.removeAttr('required');

      $addressInputFields.each(function(){
        $(this).removeAttr('required');
      });
    }
  });



  // Our document ready
  $(document).ready(function() {
    checkUrlForBookForm();

    $organizationType.on('change', organizationCallback);
    $referralQuestion.on('change', referralQuestionCallback);
  });

  $(window).on('beforeunload', function(){
    checkUrlForBookForm();
  });

})(jQuery);