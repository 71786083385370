(function($){
  'use strict';

  var $sections = $('.content').find('.subnav-link');

  $sections.each(function(){
    var $sectionTitle = $(this).html();
    var $sectionId = $sectionTitle.toLowerCase().split(' ').join('-');

    $(this).attr('id', $sectionId);
    $(this).attr('data-magellan-target', $sectionId);
  });

})(jQuery);