(function($){
  'use strict';

  // Hash linking offset
  var setPageOffset = function(){
    if ((!window.location.hash) || (window.location.hash == '#order-book')){
      return;
    }

    var $pageWrapper = $('.content');
    $pageWrapper.removeClass('deep-link-offset');

    setTimeout(function(){
      $pageWrapper.addClass('deep-link-offset');

      $(window).one('scroll', function(){
        $pageWrapper.removeClass('deep-link-offset');
      });
    }, 250);
  };


  // Set Banner Images For Blog Pages
  if($('body').hasClass('blog-banner')){
    // Set random image
    var stripImagesArray = [
      'header-item-strip-1.jpg',
      'header-item-strip-2.jpg',
      'header-item-strip-3.jpg',
      'header-item-strip-4.jpg',
      'header-item-strip-5.jpg'
    ];

    var setRandomStripImage = function(){
      var $stripItemImg = $('.blog-item-banner');
      var randomImg = stripImagesArray[Math.floor(Math.random() * stripImagesArray.length)];
      var interchange = new Foundation.Interchange($stripItemImg, {
        rules: [
          '[/sites/all/themes/outcomes_theme/assets/images/header-strip/'+ randomImg + ', medium]',
          '[/sites/all/themes/outcomes_theme/assets/images/header-strip/' + randomImg + ', large]'
        ]
       });
    };

    $(window).on('load', function(){
      setRandomStripImage();
    });
  }


  // Execute foundation
  $(document).foundation();


  // Window load event
  $(window).on('load', function(){
    setPageOffset();
    $(window).bind('hashchange', setPageOffset);

    $('.page-nav:not(.no-hover)').hoverline(
      {
        'color': '#f79219',
        'width': '110px',
        'height': '6px',
        'start': '1',
        'speed': '200',
      }
    );
  });
})(jQuery);